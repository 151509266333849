.gray-bg, .bg-muted {
  padding-bottom: 20px;
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");
html, body {
  min-height: 250px;
}
body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #f3f3f4;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;
}
.gray-bg {
  background-color: #0f6a47;
}
.loginscreen.middle-box {
  width: 300px;
}
.middle-box {
  z-index: 100;
  margin: 0 auto;
  height: calc(100vh - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.logo-name {
  color: #e6e6e6;
  font-size: 180px;
  font-weight: 800;
  margin-bottom: 0;
}
.middle-box h1 {
  font-size: 55px;
}
h3, h4, h5 {
  margin-top: 5px;
  font-weight: 600;
}
h3 {
  font-size: 16px;
}
button, input, optgroup, select, textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}
.form-control, .single-line {
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}
.form-control, .form-control:focus, .has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse, .navbar-form,
.navbar-form-custom
.form-control:focus,
.navbar-form-custom
.form-control:hover,
.open .btn.dropdown-toggle,
.panel, .popover, .progress, .progress-bar {
  box-shadow: none;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.active:focus {
  background-color: #18a689 !important;
  border-color: #18a689 !important;
  color: #FFFFFF !important;
  box-shadow: none;
}
.btn.active, .btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125)!important;
  box-shadow: inset 0 3px 5px rgba(0,0,0,.125)!important;
}
.btn-primary {
  background-color: #1ab394;
  border-color: #1ab394;
  color: #FFFFFF;
  cursor: pointer;
}
.full-width {
  width: 100% !important;
}
.form-control:focus, .single-line:focus {
  border-color: #1ab394;
}
.form_login{
  position: relative;
  padding-top: 30px;
}
.form_login .form-group{
  padding-bottom: 0;
}
.error-mess{
  color: #ed5565
}
.form_login .error-mess{
  position: absolute;
  top: 0;
}
header{
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 50px;
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  background: #fff;
  z-index: 55;
  left: 0;
  top:0;
  transition: height .3s;
}
header.sticky{
  height: 86px;
}
header .container1{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  padding: 0 15px;
}
header .left{
  display: flex;
  align-items: center;
}
header .right{
  display: flex;
}
header .user_name{
  margin-right: 15px;
  display: flex;
  align-items: center;
  padding-right: 15px;
  border-right: 1px solid #ccc;
  color: #999c9e;
  cursor: pointer;
}
header .left nav ul li a{
  font-size: 16px;
  color: #222222;
  position: relative;
  padding-bottom: 12px;
}
header .left nav ul li.active a:before{
  content: '';
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-135deg, #ccc 0%, #fff 100%);
}
header .left nav ul li a:before{
  content: '';
  height: 4px;
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-135deg, #ccc 0%, #fff 100%);
  transition: all .2s;
}
header .left nav ul li a.active:before{
  width: 100%;
}
header .left nav ul li a:hover:before{
  width: 100%
}
header .left{
  position: fixed;
  display: flex;
  left: 0;
  align-items: flex-start;
  top: 0;
  z-index:1;
  height: 100%;
  transform: translateX(0);
  transition: all 0.3s;
}
.active_menu header .left{
  transform:  translateX(-100%) ;
}
header .left nav ul{
  flex-direction: column;
  justify-content: center;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
header .left .nav_cont{
  flex-direction: column;
  padding: 15px;
  background: #0f6a47;
  height: 100%;
  overflow: auto;
  /*width: 335px;*/
}
header .left nav ul li{
  padding: 0 15px;
  padding-bottom: 20px;
}
.nav_button{
  display: block;
  padding: 12px;
  width: 57px;
  height: 59px;
  box-sizing: border-box;
  margin-top: 6px;
  padding-top: 0;
  cursor: pointer;
  position: absolute;
  right: -53px;
}


.nav_button .icon-bar {
  width: 30px;
  height: 5px;
  background: #0f6a47;
  border-radius: 5px;
  margin: 5px 0;
}


.nav_button .bar1,.nav_button .bar3 {
  transition: all 0.3s;
}
.nav_button .bar1 {
  transition: all 0.3s;
  transform: rotate(45deg) translateX(7px);
}
.nav_button{
  margin-top: 12px;
}
.nav_button .bar2 {
  display: none;
}
.nav_button .bar3 {
  transition: all 0.3s;
  transform: rotate(-45deg) translateX(7px);
}
.active_menu .nav_button .icon-bar{
  transform: rotate(0) translateX(0);
}
.active_menu .nav_button .bar2 {
  display: block;
}
.active_menu .nav_button{
  margin-top: 6px;
}

.row{
  margin: 0;
}
header .left nav ul li a{
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 600;
}
header .left nav ul li a i{
  padding-right: 10px;
}
header .left nav ul li a img, header .left nav ul li a svg{
  margin-right: 10px;
}
header .left nav ul li .drop_menu ul a{
  color: #000;
}


a, a:hover, a:focus {
  text-decoration: none;
}
.loading{
  height: 4px;
  background: #15545d;
  z-index: 99999999;
  position: fixed;
  top:0;
  left: 0;
}
.logout{
  cursor: pointer;
  color: #999c9e;
  font-size:14px;
  font-weight: 600;
}
.logout i {
  margin-right: 6px;
}
.logo{
  width: 120px;
  margin-left: 15px;
  margin-bottom: 15px;
}
.main_container{
  margin: 70px 0 0 273px;
  transition: all .3s;
  padding: 15px 25px;
}
.main_container.admin1{
  margin: 70px 0 0 330px;
}
.active_menu .main_container{
  margin: 70px 0 0 0 ;
}
.active_menu .main_container.admin1{
  margin: 70px 0 0 0 ;
}
.search input{
  width: 250px;
  padding-right: 36px;
}
.search{
  position: relative;
}
.form_search{
  display: flex;
  margin-bottom: 15px;
}
.fix-width-300{
  width: 300px;
}
.form_search.mod_transactions{
  flex-wrap: wrap;
}
.m_form.form_search label{
  padding-bottom: 0;
  position: relative;
}
.m_form.form_search label .remove_search{
  font-weight: bold;
  width: 20px;
  height: 20px;
  background-color: #cecece;
  position: absolute;
  right: 6px;
  top: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.mrg_l, .m_form.form_search label.search{
  margin-right: 10px;
}
.search1{
  position: relative;
  height: 30px;
  border: none;
  outline: none;
  width: 100px;
  background: #0f6a47;
  padding-right: 25px;
  color: #fff;
  margin-left: 10px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 21px;
}
.search1:after{
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f002";
  font-size:14px;
  position: absolute;
  right: 9px;
  top: 7px;
  color: #cbcbce;
}
table tr th.clickable{
  cursor: pointer;
  position: relative;
  padding-right: 20px;
}
table tr th.clickable:after{
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0dc";
  font-size:14px;
  position: absolute;
  right: 10px;
  top: 15px;
  color: #cbcbce;
}
table tr th.clickable.active_up:after{
  content: "\f0de";
  color: #676a6c;
}
table tr th.clickable.active_down:after{
  content: "\f0dd";
  color: #676a6c;
}
.chart_container{
  height:300px;
  padding: 0 15px;
  background: #fff;
  width: 100%;
  position: relative;
}

.cont1{
  width: calc(33.333% - 30px);
  background: #fff;
  margin: 15px;
  height:100%;
}
.cont2{
  width: calc(50% - 30px);
  margin: 15px;
  min-width: 800px;
}
.cont2.mod_dashb{
  margin: 30px auto;
}
.cont2.mod_dashb table tr td:nth-child(3), .cont2.mod_dashb table tr th:nth-child(3){
  text-align: center;
}
.chart_container1{
  width: 100%;
  padding-top: 60%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}
.recharts-responsive-container{
  position: absolute;
  left:0;
  top:0;
}
.chart_head {
  background-color: #ffffff;
  border-top: 2px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 15px 7px;
  min-height: 48px;
  display: flex;
  justify-content:space-between;
}
.chart_head input{
  width: 190px;
  font-size: 14px;
  height: 30px;
}
.head_txt{
  font-size:18px;
  font-weight: 600;
}
.modal-content{
  position: relative;
}
button.close_mod {
  border: none;
  outline: none;
  background: #fff;
  font-size: 23px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 1;
}
.modal_head {
  font-size: 24px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-right: 19px;
  border-bottom: 1px solid #ccc;
  min-height: 25px;
}
span.label_txt {
  padding-bottom: 2px;
  display: block;
  font-weight: 600;
}
.m_form label{
  position: relative;
  padding-bottom: 20px;
}
.m_form label.mod_margin{
  padding-bottom: 0;
}
.m_form label.error_lab input, .m_form label.error_lab textarea, .m_form label.error_lab select{
  border: 1px solid #ed5565;
}
.m_form label textarea{
  resize: none;
  height:130px;
}
span.error_mes {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 11px;
  color: #ed5565;
}
.m_form label input {
  height: 30px;
}
.but_cont{
  display: flex;
  justify-content:space-between;
}
.but_cont button{
  width: calc(50% - 10px);
}

.actions .edit,.actions .delete{
  cursor: pointer;
  font-size:22px;
}
.actions .edit {
  color: green;
}
.actions .delete {
  color: red;
}
.actions .edit:before {
  content: "\f044";
}
.actions .delete:before {
  content: "\f1f8";
}
.actions .delete{
  padding-left: 10px;
}
.widget{
  padding: 25px;
  color: #ffffff;
  display: flex;
}
.widget > img{
  margin-right: 15px;
}
.color1{
  background-color: #484848;
}
.color3{
  background-color: #1ab394;
}
.color2{
  background-color: #0f6a47;
}
.txt_widget{
  font-size:20px;
}
.txt_lab{
  margin-left:10px;
}
.pict img{
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.remove_pic{
  margin-left:15px;
  cursor: pointer;
}
.remove_pic:before{
  content: "\F1F8";
  font-size:22px;
  margin-right: 5px;
}
.img_edit{
  display: flex;
  align-items: center;
  margin-bottom:10px;
}
.img_edit img{
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.main_container table td{
  vertical-align: middle;
  white-space: normal;
}
.main_container table td.actions.mod1 .but_table{
  text-align: center;
}
.main_container table td.actions.mod1 .but_table.but1{
  margin-top: 5px;
}
.address_table{
  color: blue;
  cursor: pointer;
}
.pagin_cont{
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.pagin_cont .ic_left:after{
  content: '';
  display: block;
  width: 23px;
  height: 23px;
  background: url('../img/active_right.svg') no-repeat center;
  transform: rotate(-180deg);
}
.pagin_cont .ic_right:after{
  content: '';
  display: block;
  width: 23px;
  height: 23px;
  background: url('../img/active_right.svg') no-repeat center;
}
.pagination li a{
  border: none;
  width: 42px;
  height: 42px;
  margin: 0 7px;
  border-radius: 50%;
  cursor: pointer;
  font-family: Montserrat-Medium;
  font-size: 14px;
  color: #7C86A2;
  text-align: center;
  line-height: 25px;
}
.pagination li:first-child a,.pagination li:nth-child(2) a,.pagination li:last-child a, .pagination li:nth-last-child(2) a  {
  width: 35px;
  padding: 0.5rem 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.pagination li:first-child a:hover,.pagination li:nth-child(2) a:hover,.pagination li:last-child a:hover, .pagination li:nth-last-child(2) a:hover{
  background: transparent;
  color: #7C86A2;
}
.pagination li.active a {
  background: #0f6a47 !important;
  border:none;
  box-shadow:none;
}
.f_link2:after{
  content: '';
  display: block;
  width: 23px;
  height: 23px;
  background: url(../img/active_right.svg) no-repeat center;
  transform: rotate(-180deg);
}
.b_link2:after{
  content: '';
  display: block;
  width: 23px;
  height: 23px;
  background: url(../img/active_right.svg) no-repeat center;
  transform: rotate(0);
}
.pagination li.disabled a {
  opacity: 0.4;
}
.pagination li a {
  position: relative;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.pagination li a:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.pagination li.active a{
  color: #fff;
}
.pagination .li.disabled a:hover{
  background: transparent !important;
  border:none;
  box-shadow:none;
}
.pagination li a.disabled {
  opacity: 0.4;
}
select{
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 0 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  height:30px;
  font-size: 1rem;
  line-height: 1.5;
}
select:focus{
  border: 1px solid #1ab394;
  box-shadow: none;
  outline: none;
}
table tr.edited {
  outline: 1px solid red;
}
.table td{
  border-top: none;
}
.color2 .table{
	color: #fff;
}
span.fa-eye.fa {
  font-size: 22px;
  color: #263a4a;
  margin-left: 7px;
  cursor: pointer;
}
.list_data{
  margin: 0;
  padding: 0;
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -moz-column-count: 2; /* Firefox */
  column-count: 2;
  -webkit-column-gap: 35px; /* Chrome, Safari, Opera */
  -moz-column-gap: 35px; /* Firefox */
  column-gap: 35px;
  -webkit-column-rule-style: solid; /* Chrome, Safari, Opera */
  -moz-column-rule-style: solid; /* Firefox */
  column-rule-style: solid;
  column-rule-width: 2px;
  list-style: none;
}
.list_data li{
  margin-bottom:5px;
}
.dont_list{
  font-size:18px;
}
.table_head_txt {
  display: none;
  font-weight: bold;
  padding-right: 10px;
}
.selectBox{
  margin-bottom:15px;
  width: 100%;
  height:38px;
  display: none;
}
span.fa-envelope.fa {
  font-size: 20px;
  padding-left: 5px;
  color: #5e7792;
  cursor: pointer;
}
.head_mess {
  text-align: center;
  font-size: 34px;
  margin-bottom: 15px;
}
span.fa.fa-check {
  font-size: 70px;
  color: #3fdfae;
  text-align: center;
}
span.fa.fa-warning {
  font-size: 70px;
  color: red;
  text-align: center;
}
.m_lbl{
  margin-right:25px;
  margin-top: 20px;
}
.m_form label.m_lbl input{
  width: 240px;
  height:37px;
  color: #000;
  padding-right: 25px;
}
.m_form label.m_lbl:after{
  content: '$';
  display: block;
  position: absolute;
  right: 10px;
  top: 4px;
  color: #827878;
}
.width_95{
  width: 95px;
}
.forgot_pass{
  margin-top: 15px;
  text-align: right;
  cursor: pointer;
  color: #ffffff;
}
.balance{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}
.balance .d-flex{
  font-size: 36px;
}
.balance .sum{
  padding: 0 10px;
}
.but_open{
  padding: 10px 15px;
  background-color: #0f6a47;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
  font-size: 18px;
}
.modal-content .balance .sum{
  padding: 0 5px;
}
.modal-content .balance{
  margin-top: 0;
  margin-bottom: 10px;
}
.modal-content .balance .d-flex  {
  font-size: 16px;
  align-items: center;
}
.top_container_search{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.add_but{
  border: none;
  padding: 0 15px;
  min-width: 120px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  background-color: #0f6a47;
  margin-top: 8px;
  margin-bottom: 10px
}
.add_but.exel_but{
  margin-right: 10px;
}
.add_but.exel_but_trans{
    margin-top: 21px;
  margin-left: 10px;
}
.calendar_cont .react-datepicker-component .react-datepicker-input{
  background: #fff;
  border-radius: 0;
  width: 150px;
  border: 1px solid #e5e6e7;
  height: 30px;
}
.calendar_cont .react-datepicker-component .react-datepicker-input:hover,
.calendar_cont .react-datepicker-component .react-datepicker-input.is-open{
  background: #fff;
  border: 1px solid #e5e6e7;
}
.tab_top {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}
.tab_top .card-tab{
  background-color: #b5b5b5;
  color: #fff;
  width: 300px;
  height: 35px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  position: relative;
}
/*.tab_top .card-tab:after{*/
  /*content: '';*/
  /*position: absolute;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*height: 3px;*/
  /*width: 0;*/
  /*background-color: green;*/
  /*transition: all .2s;*/
/*}*/
.tab_top .card-tab.active, .tab_top .card-tab:hover{
    background: #0f6a47;
}
.but_table{
  background-color: #0f6a47;
  color: #fff;
  padding: 5px 7px;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
}
.checkbox_lab{
  display: flex;
  align-items: center;
}
.m_form label.checkbox_lab{
  margin-bottom: 0;
  padding-bottom: 10px;
}
.fix-label.m_form label.checkbox_lab{
  width: 165px;
}
.checkbox_lab input{
  width: 15px;
  height: 15px;
  margin-right: 15px;
  flex-shrink: 0;
}
.checkbox_lab.mod1 input{
  margin-left: 10px;
}
.but_cont_search{
  display: flex;
}
.but_cont_search .but2{
  margin-left: 10px;
}
a.add_but{
  color:#fff;
}
label.add_but input{
  display: none;
}
.but_container_bot{
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.but_container_bot.but_cont_search .add_but.inp_file{
  margin-left: 10px;
}
.balance.cardholder .d-flex{
  font-size: 24px;
  margin-bottom: 10px;
}
.nav_cont .info_card{
  color: #fff;
}
.nav_cont .info_card .fa{
  font-size: 24px;
}
.checkedList{
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  min-height: 33px;
  position: relative;

}
.checkedList > span.arr{
  position: absolute;
  right: 0px;
  top: 8px;
  width: 13px;
  height: 13px;
  display: block;
  -webkit-appearance: menulist;
}
.multy_check{
  position: relative;
  margin-bottom: 10px;
}
.multy_check .drop_down_list{
  background-color: #fff;
  border: 1px solid #e5e6e7;
  width: 100%;
  z-index: 1;
  padding: 0 15px;
  display: none;
}
.table .table thead th, .table .table tbody td{
  padding: 5px;
}
.table thead th{
  vertical-align: top;
}
.point_click{
  cursor: pointer;
  color: blue;
}
.select-box .react-select__control{
  border-radius: 0;
}
.driver_data{
  justify-content: space-between;
  margin-bottom: 15px;
  display: flex;
}
.driver_data .col1:not(:last-child){
  margin-right: 10px;
}
.driver_data .col1 span{
  font-weight: bold;
  display: block;
}
.multy_fuelType{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  margin-bottom: 10px;
  position: relative;
}
.error_message{
  position: absolute;
  color: red;
  font-size: 10px;
  right: 0;
  bottom: 0;
}
.multy_fuelType label{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 0;
}
.multy_fuelType label.mod_check{
  flex-direction: row;
  align-items: center;
  width: 280px;
}
.multy_fuelType label.mod_check .label_txt{
  margin-bottom: 0;
}
.multy_fuelType .txt_lab{
  width: 141px;
}
.multy_fuelType.mod1 .txt_lab{
  width: 178px;
}
.multy_fuelType.mod1 label.mod_check{
  width: 182px;
}
.multy_fuelType .label_txt{
  margin-bottom: 10px;
  font-size: 12px;
}
span.label_txt.center_mod{
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
  flex: 1;
}
.react-datepicker__input-container input {
  height: 30px;
  border: 1px solid #e5e6e7;
  padding: 0 5px;
}
#excel1, #excel2{
  display: none;
}
.loader{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-inp-price{
  display: flex;
  margin: 10px 0;
}
.flex-inp-price .txt_lab{
  display: flex;
  margin-left: 15px;
  padding-bottom: 0;
}
.flex-inp-price .txt_lab .label_txt{
  margin-left: 15px;
  width: 54px;
  margin-left: 15px;
}
.flex-inp-price .checkbox_lab .label_txt{
  min-width: 60px;
}
.all-station-select{
  display: flex;
  align-items: center;
}
.all-station-select label{
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.all-station-select label span{
  margin: 0 5px;
}
.select_office{
  display: flex;
}
.select_office label{
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.select_office label span{
  margin-right: 5px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input{
  width: 100px;
}
.language_container button{
  width: 38px;
  height: 26px;
  background: transparent;
  border-radius: 5px;
  margin: 0 5px;
  border: 1px solid;
  outline: none;
}
.language_container button.active{
  background: #0f6a47;
  color: #fff;
}
.global_error{
  display: flex;
  justify-content: center;
  margin-top: 15px;
  color: #ed5565;
  font-weight: bold;
}
.display_none{
  display: none;
}
.toggle_button{
  width: 250px;
  margin-bottom: 15px;
}
.toggle_button svg{
  margin-left: 10px;
  font-weight: bold;
}
.toggle_button.reverse svg{
  transform: rotate(180deg);
}
.pointer{
  cursor: pointer;
}
.fix_width_label{
  width: 220px;
}
.req-star, .red_color{
  color: red;
}
.file_new_input input{
  display: none;
}
.file_new_input{
  display: flex;
  flex-direction: column;
}
.file_new_input .file_text{
  font-size: 18px;
}
.react-datepicker__input-container input{
  color: black;
}
.select-multi-react label{
  margin-bottom: 0;
}
.select-multi-react .react-select__option{
  display: flex;
  align-items: center;
}
.select-multi-react .react-select__option input{
  margin-right: 7px;
}