@media(max-width: 1300px){
    .cont2.mod_dashb{
        min-width: 500px;
    }
}
@media(max-width: 991px){
    .form_search{
        flex-wrap: wrap;
    }
    .tab_top .card-tab{
        font-size: 12px;
    }
    .main_container{
        padding: 15px 10px;
    }
    .cont1{
        width: 100%;
    }
    .cont2{
        width: 100%;
        min-width: 100%;
    }
    .multy_fuelType{
        flex-direction: column;
    }
    .multy_fuelType .txt_lab{
        flex-direction: row;
        margin-left: 0;
    }
    .multy_fuelType .label_txt{
        width: 150px;
        margin-right: 5px;
    }
    .multy_fuelType label{
        flex-direction: row;
    }
    .multy_fuelType .txt_lab{
        width: 100%;
    }
}
@media(max-width: 768px){
    .cont2.mod_dashb{
        min-width: initial;
    }
    header .user_name{
        border-right: 0;
    }
    .but_cont_search{
        flex-wrap: wrap;
    }
    .main_container table thead{
        display: none;
    }
    .table_head_txt {
        display: block;
    }
    .main_container table tr{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 30px;
        border: 1px solid;
      }
    .main_container table table tr{
        margin-bottom: 0;
    }
    .main_container table td {
        vertical-align: middle;
        display: flex;
        width: 100%!important;
        max-width: 100%!important;
        border-bottom:1px solid #ccc;
        align-items: center;
    }
    .add_but.mod_height{
        height: 43px
    }
    .main_container table td.actions.mod1{
        flex-direction: column;
    }
    header .right {
        display: flex;
        flex-wrap: wrap;
        margin-left: 48px;
    }
    .selectBox{
        display: block;
    }
    .search input, .search{
        width: 100%;
    }
    .main_container, .main_container.admin1{
        margin: 90px 0 0 0;
    }
    .nav_button {
        margin-top: 6px;
    }
    .nav_button .icon-bar{
        transform: rotate(0) translateX(0);
    }
    .nav_button .bar2 {
        display: block;
    }
    header .left{
        transform:  translateX(-100%);
    }
    .act_but.nav_button {
        margin-top: 12px;
    }
    .active_menu .nav_button .icon-bar {
        transition: all 0.3s;
        -webkit-transform: rotate(45deg) translateX(7px);
        transform: rotate(45deg) translateX(7px);
    }
    .active_menu .nav_button .bar2 {
        display: none;
    }
    .active_menu .nav_button .bar3 {
        transition: all 0.3s;
        -webkit-transform: rotate(-45deg) translateX(7px);
        transform: rotate(-45deg) translateX(7px);
    }
    .active_menu  .main_container{
        margin: 70px 0 0 0;
    }
    .active_menu header .left {
        -webkit-transform: translateX(0) translateX(0);
        transform: translateX(0) translateX(0);
    }
    /*.pagination li:first-child, .pagination li:last-child {*/
        /*display: none;*/
    /*}*/
}
@media (min-width: 576px){
    .modal-dialog.large {
        max-width: 900px;
    }
}

